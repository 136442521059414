<template>
  <q-page class="flex flex-center bg-primary auth">
    <q-card class="my-card">
      <q-form @submit="updatePassword(form)">
        <q-card
          class="register-account"
          :bordered="!$q.platform.is.mobile"
          flat
          :class="$q.platform.is.mobile ? 'q-pa-sm  transparent' : 'q-pa-md'"
          :style="$q.platform.is.mobile ? `width: ${$q.screen.width - 10}px` : 'width: 512px'"
        >
          <q-card-section class="text-center q-pt-md q-pb-none">
            <q-img
              :src="require('@/assets/images/logo_color.png')"
              spinner-color="white"
              width="168px"
              spinner-size="82px"
            />
          </q-card-section>
          <q-card-section class="q-col-gutter-md">
            <q-input
              clearable
              clear-icon="close"
              ref="email"
              outlined
              type="email"
              label="Email"
              v-model="form.email"
              lazy-rules
              :rules="[isRequired, isEmail]"
            >
            </q-input>

            <q-input
              clearable
              clear-icon="close"
              ref="password"
              outlined
              :label="$t('password')"
              v-model="form.password"
              :type="showPassword ? 'text' : 'password'"
              lazy-rules
              :rules="[isRequired]"
            >
              <template v-slot:append>
                <q-btn
                  flat
                  no-caps
                  :label="showPassword ? $t('hide') : $t('show')"
                  @click="showPassword = !showPassword"
                />
              </template>
              <template v-slot:hint> </template>
              <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px">
                <b>Sua senha deve conter no mínimo:</b>
                <ul>
                  <li>8 Caracteres</li>
                  <li>1 Letra Maiúscula</li>
                  <li>1 Letra minúscula</li>
                  <li>1 Número</li>
                  <li>1 Caractere Especial</li>
                </ul>
              </q-tooltip>
            </q-input>

            <q-linear-progress
              style="top: 2px"
              size="25px"
              :color="verifyPasswordStrength.color"
              :value="verifyPasswordStrength.value"
            >
              <div class="absolute-full flex flex-center" v-if="verifyPasswordStrength.label">
                <q-badge
                  color="white"
                  :text-color="verifyPasswordStrength.color"
                  :label="verifyPasswordStrength.label"
                />
              </div>
            </q-linear-progress>

            <q-input
              clearable
              clear-icon="close"
              ref="checkPassword"
              outlined
              :label="$t('confirm_pass')"
              v-model="form.password_confirmation"
              :type="showCheckPassword ? 'text' : 'password'"
              lazy-rules
              :rules="[isRequired]"
            >
              <template v-slot:append>
                <q-btn
                  flat
                  no-caps
                  :label="showCheckPassword ? $t('hide') : $t('show')"
                  @click="showCheckPassword = !showCheckPassword"
                />
              </template>
            </q-input>
          </q-card-section>
          <q-card-section class="q-px-md row justify-between q-pt-md">
            <q-btn
              unelevated
              outline
              no-caps
              color="primary"
              class="q-py-sm q-px-lg"
              :label="$t('cancel')"
              to="/auth/login"
            />
            <q-btn unelevated color="primary" no-caps class="q-py-sm q-px-lg" label="Enviar" type="submit" />
          </q-card-section>
        </q-card>
      </q-form>
    </q-card>
  </q-page>
</template>
<script>
export default {
  name: 'FirstAccess',
  mixins: [],
  props: {
    hash: {
      type: String,
      require: true,
      default: null
    }
  },
  data() {
    return {
      form: {
        password: null,
        password_confirmation: null,
        email: null
      },
      showPassword: false,
      showCheckPassword: false
    };
  },
  computed: {
    verifyPasswordStrength() {
      const score = this.scorePassword(this.form.password);
      if (score > 80) return { label: 'Forte', value: 1, color: 'positive' };
      if (score > 60) return { label: 'Boa', value: 0.7, color: 'info' };
      if (score >= 30) return { label: 'Fraca', value: 0.5, color: 'warning' };
      if (score >= 20) return { label: 'Muito fraca', value: 0.2, color: 'negative' };
      return { label: '', value: 0, color: '' };
    }
  },
  methods: {
    // async onChangePassword() {
    //     try {
    //         if (!this.form.password) throw "Senha não informada";
    //         if (this.form.password !== this.form.password_confirmation) throw "Senhas não conferem";

    //         const { status } = await this.$http.put(
    //         "/edit/user/resetaffiliatepass/" + this.id,
    //         { password: this.form.password });
    //         if (status == 200) {
    //             this.passwordDialog = false
    //             this.successNotify("Senha Atualizada com sucesso");
    //         }

    //     } catch (error) {
    //         this.errorNotify(error);
    //     }
    // },

    async updatePassword(form) {
      form['token'] = this.hash;
      this.onLoading(true);

      try {
        if (!this.form.password) throw 'Senha não informada';
        if (this.form.password !== this.form.password_confirmation) throw 'Senhas não conferem';

        const { status } = await this.$http.put('/edit/user/resetpassword', form);
        if (status === 200 || status === 201) {
          this.successNotify('Senha alterada com sucesso!');
          this.$router.push({ name: 'Auth' });
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    }
  }
};
</script>

<style>
.auth .q-select__dropdown-icon {
  color: white;
}
</style>
